import React, { useState } from "react";
import  { useEffect } from 'react';
import useDrivePicker from 'react-google-drive-picker'
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import classes from "./GoogleDrivePicker.module.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

let videoData;

const GoogleDrivePicker = () => {
  const [openPicker, authResponse] = useDrivePicker();  
  // const customViewsArray = [new google.picker.DocsView()]; // custom view
  const handleOpenPicker = () => {
    
    openPicker({
      clientId: "400703545151-e6e6l9uve057j9249oqke2qhi7qhn158.apps.googleusercontent.com",
      developerKey: "AIzaSyC6DRj4KtiV5mkd2xBXI5horDG9Kj-sw1I",
      viewId: "DOCS",
      // token: token, // pass oauth token in case you already have one
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      // customViews: customViewsArray, // custom view
      callbackFunction: (data) => {
        if (data.action === 'cancel') {
          console.log('User clicked cancel/close button')
        }
        console.log(data)
        videoData = data;
      },
    })
  }

  function onClickHandler(ev) {
    var el = window._protected_reference = document.createElement("INPUT");
    el.type = "file";
    el.accept = "video/*";
    // el.multiple = "multiple"; // remove to have a single file selection
    
    // (cancel will not trigger 'change')
    el.addEventListener('change', function(ev2) {
      // access el.files[] to do something with it (test its length!)
      
      // add first image, if available
      if (el.files.length) {
        const para = document.createElement("p");
        para.setAttribute("id", "out")
        para.innerHTML = el.files[0].name;
        document.getElementById("xyz").appendChild(para);
      }
  
  
      // test some async handling
      new Promise(function(resolve) {
        setTimeout(function() { console.log(el.files); resolve(); }, 1000);
      })
      .then(function() {
        // clear / free reference
        el = window._protected_reference = undefined;
      });
  
    });
  
    el.click(); // open
  }

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the first selected file
    setSelectedFile(file);
  };

  const [apiData, setApiData] = useState(0);

  const fetchData = async (title, description, tags, license, privacy) => {
  
    try {
      console.log(typeof(videoData));
      const response = await axios.post('https://uploadqast.com/upload/', {
        'title': title,
        'description': description,
        'video_id': videoData.docs[0].id,
        'tags': tags,
        'license': license,
        'privacy': privacy
      });
      console.log(response);
      window.location.href=response['data']
      setApiData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  return (
    
    <div id="xyz">
      <button type="button" class="btn btn-dark btn-lg download-button" onClick={() => handleOpenPicker()}><i class="fab fa-google-drive"></i> Choose drive file</button>
      <form>
        <div class="form-group">
          <label for="title">Title</label>
          <input type="text" class="form-control" id="titleInput" maxLength="100" placeholder="Add a title that describes your video (type @ to mention a channel)" required/>
          {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
        </div>
        <div class="form-group">
          <label for="description">Description</label>
          <input type="text" class="form-control" id="description" placeholder="Tell viewers about your video (type @ to mention a channel)" row/>
        </div>
        <div class="form-group">
          <label for="tags">Tags</label>
          <input type="text" class="form-control" id="tags" placeholder="Add comma separated tags"/>
        </div>
        <label class="mr-sm-2" for="inlineFormCustomSelect">License</label>
        <select class="custom-select mr-sm-2" id="license">
          {/* <option selected>Standard YouTube Licence</option> */}
          <option value="youtube">Standard YouTube Licence</option>
          <option value="creativeCommon">Creative Commons – Attribution</option>
        </select>
        <label class="mr-sm-2" for="inlineFormCustomSelect">Privacy Status</label>
        <select class="custom-select mr-sm-2" id="privacy">
          {/* <option selected>Standard YouTube Licence</option> */}
          <option value="private">private</option>
          <option value="public">public</option>
          <option value="unlisted">unlisted</option>
        </select>
        <br />
        <br />
        <button type="button" class="btn btn-primary" onClick={() => fetchData(document.getElementById('titleInput').value,document.getElementById('description').value, document.getElementById('tags').value, document.getElementById('license').value, document.getElementById('privacy').value)}>Upload to YouTube</button>
      </form>
    </div>
  );
}

export default GoogleDrivePicker;


